import React, {useState, useContext, useEffect, useRef} from "react";

import styles from '../Styles/AnswerView.module.css';
import {Breadcrumbs, Carousel} from 'components';
import logo from 'assets/images/quiz-logo.png';
import HubContext from "utils/hubProvider";
import { PlayerImage } from "components/PlayerImage/PlayerImage";
import { Flipped, Flipper } from "react-flip-toolkit";
import { delay } from "utils/helper";
import { useAudioPlayer } from 'react-use-audio-player';
import VolumeContext from "utils/volumeProvider";
import thud from 'assets/media/ding.mp3';
import error from 'assets/media/error.mp3';
import sweep from 'assets/media/sweep.mp3';
import finalThud from 'assets/media/final_thud.mp3';
import countdown from 'assets/media/countdown.mp3';
import SizedConfetti from "react-confetti";
import useScreenSize from "../../../hooks/useScreenSize";
import {useDimensions} from "../../../hooks/useContainerDimensions";

export function PointlessAnswerView(props) {
    const counterSpeed = 70;
    const matchDelaySpeed = 0;
    const wrongDelaySpeed = 2000;
    
    const hub = useContext(HubContext);
    const audioPlayer = useAudioPlayer();
    const countdownPlayer = useAudioPlayer();
    const resultPlayer = useAudioPlayer();
    const volume = useContext(VolumeContext);
    const { width, height } = useScreenSize()
    const countdownRef = useRef(null)
    const { width: countdownWidth, height: countdownHeight, x: countdownX, y: countdownY } = useDimensions(countdownRef)
    
    const [hasSortedResults, setHasSortedResults] = useState(false);
    const [answerResults] = useState(props.data.answer?.answer.map(e => {
        const number = e.match("\\[.+?\\]")[0];

        return {
            value: e.replace(number, "").trim(),
            score: parseInt(number.replace(/[\[\]']+/g,''))
        }   
    }).sort((a, b) => b.score - a.score));

    const [counter, setCounter] = useState(100);
    const [hitAnswers, setHitAnswers] = useState(props.data.playerAnswers
        .filter(e => e.correct[0] ?? false)
        .map(e => {
            const number = e.closestMatch[0].value.match("\\[.+?\\]")[0];

            return {
                value: e.closestMatch[0].value.replace(number, "").trim(),
                score: parseInt(number.replace(/[\[\]']+/g,'')),
                visible: false,
            }
        })
        .filter((value, index, self) => index === self.findIndex((t) => t.value === value.value))
        .sort((a, b) => b.score - a.score));
    
    const [playerAnswers, setPlayerAnswers] = useState(props.data.playerAnswers.map(e => {
        let displayAnswer = (e.correct[0] ?? false) ? e.closestMatch[0].displayValue : e.answer[0];
        let displayScore = (e.correct[0] ?? false) ? answerResults.find(e => e.value === displayAnswer)?.score ?? -1 : -1;

        return {
            answer: e,
            displayAnswer: displayAnswer,
            displayScore: displayScore,
            visible: false,
        }
    }));
    // }).sort((a, b) => b.displayScore - a.displayScore));

    const [showConfetti, setShowConfetti] = useState(false);


    const sortResults = () => {
        setHasSortedResults(true);

        audioPlayer.load(sweep, { autoplay: true, initialVolume: volume.sfx});
        
        //sort players by score and show points
        let sorted = [...playerAnswers];
        sorted.sort(sortAns);
        setPlayerAnswers(sorted);
    }

    const [showInfo, setShowInfo] = useState(false)
    useEffect(() => {
        hub.off('info-toggle');
        hub.on('info-toggle', (show) => {
            setShowInfo(show);
        });
    }, [hub])

    useEffect(() => {
        countdownPlayer.load(countdown, { initialVolume: volume.sfx })
        audioPlayer.load(thud, {initialVolume: volume.sfx});
        resultPlayer.load(finalThud, {initialVolume: volume.sfx});
        
        setCounter(100);
        
        async function animate() {
            await delay(3000);
            
            var anyCorrect = playerAnswers.some(e => e.answer.correct[0] ?? false);

            if(anyCorrect && playerAnswers.some(e => e.displayScore > 0)) {
                countdownPlayer.play();
                for (let i = 100; i >= 0; i--) {
                    setCounter(i);

                    let newHitAnswers = [...hitAnswers];
                    let newPlayerAnswers = [...playerAnswers];
                    let hitAns = false;
                    for (let j = 0; j < newHitAnswers.length; j++) {
                        if (newHitAnswers[j].score === i) {
                            newHitAnswers[j].visible = true
                            hitAns = true;
                        }
                    }

                    if (hitAns) {
                        setHitAnswers(newHitAnswers);

                        for (let j = 0; j < newPlayerAnswers.length; j++) {
                            if (newPlayerAnswers[j].displayScore === i) {
                                newPlayerAnswers[j].visible = true
                            }
                        }
                        setPlayerAnswers(newPlayerAnswers);


                        audioPlayer.play(thud);
                        await delay(matchDelaySpeed);
                    }

                    await delay(counterSpeed);

                    //exit if all answers found 
                    if (newPlayerAnswers.every(e => e.displayScore < 0 || e.visible)) {
                        
                        if(i !== 0) {
                            countdownPlayer.stop();
                            resultPlayer.play();
                        }
                        else {
                            setShowConfetti(true);
                            await delay(2000);
                        }
                      
                        break;
                    }
                }
            }

            if(!anyCorrect || playerAnswers.some(e => e.displayScore === -1))
            {
                console.log(playerAnswers)

                await delay(wrongDelaySpeed);

                resultPlayer.load(error, {autoplay:true, initialVolume: volume.sfx});

                let newPlayerAnswers = [...playerAnswers];
                for (let j = 0; j < newPlayerAnswers.length; j++) {
                    if(newPlayerAnswers[j].displayScore === -1) {
                        newPlayerAnswers[j].visible = true
                    }
                }
            }


            if(anyCorrect && playerAnswers.some(e => e.displayScore > 0)) {

                await delay(5000)

                sortResults();
            }
        }

        animate();

    }, []);

    function sortAns(ob1, ob2) {
        if (ob1.answer.score < ob2.answer.score) {
            return 1;
        } else if (ob1.answer.score > ob2.answer.score) {
            return -1;
        }
        
        var ob1DisplayScore = parseFloat(ob1.displayScore)
        var ob2DisplayScore = parseFloat(ob2.displayScore)

        ob1DisplayScore = ob1DisplayScore === -1 ? 999 : ob1DisplayScore;
        ob2DisplayScore = ob2DisplayScore === -1 ? 999 : ob2DisplayScore;

        // Else go to the 2nd item
        if (ob1DisplayScore < ob2DisplayScore) {
            return -1;
        } else if (ob1DisplayScore > ob2DisplayScore) {
            return 1
        } else { // nothing to split them
            return 0;
        }
    }



    function buildPlayers() {
        const list1 = [];
        const list2 = [];
        for (let i = 0; i < playerAnswers.length; i++) {
            const answer = playerAnswers[i].answer;
            const visible = playerAnswers[i].visible;
            const player = props.data.players.find(e => e.id === answer.userId);

            const score = hasSortedResults ? answer?.score : null;

            let displayAnswer = playerAnswers[i].displayAnswer;
            let displayScore = playerAnswers[i].displayScore;
            let correct = displayScore > -1;
            
            var item = null;
            if(visible){
                var colour = displayScore === 0 ? '#FFD700' : percentageToColor(displayScore);
                
                item = (
                    <Flipped key={i} flipId={player.id}>

                        <div key={i} className={`${styles.player_answer} ${score ? styles.has_score : ""} ${styles.player_visible} ${!correct ? styles.error : ""} ${hasSortedResults ? "" : styles.can_scale}`}
                            style={{border: `3px solid ${colour}`}}
                        >

                            {correct ?
                                <div className={styles.position} style={{background: colour}}>{displayScore}</div> :
                                <div className={styles.position}>X</div> }

                            <div className={styles.player_container}>
                                <PlayerImage className={styles.player_image} player={player} withoutName={true} />
                            </div>

                            <div className={styles.player_content}>
                                <p className={styles.player_answer_val}>{displayAnswer}</p>
                                <p className={styles.player_name}>{player.name}</p>
                            </div>
                            {score ? <span className={`${styles.score} ${displayScore === 0 ? styles.zero : ""}`}>+{score}</span> : null}
                        </div>

                    </Flipped>
                );
            }
            else {
                item = (
                    <Flipped key={i} flipId={player.id}>

                        <div key={i} className={`${styles.player_answer} ${score ? styles.has_score : ""}`}>
                            <div className={styles.position}></div>
                            
                            <div className={styles.player_container}>
                                <PlayerImage className={styles.player_image} player={player} withoutName={true}/>
                            </div>

                            <div className={styles.player_content}>
                                <p className={styles.player_answer_val}>{displayAnswer}</p>
                                <p className={styles.player_name}>{player.name}</p>
                            </div>
                            {score ? <span className={styles.score}>+{score}</span> : null}
                        </div>

                    </Flipped>
                );
            }
            
            if(i < Math.ceil(playerAnswers.length / 2))
                list1.push(item);
            else
                list2.push(item);
        }
        
        return [list1, list2];
    }

    function percentageToColor(percentage, maxHue = 175, minHue = 50) {
        if(percentage === -1){
            return "var(--color-red)"
        }

        var perc = (100 - percentage) / 100;
        
        const hue = perc * (maxHue - minHue) + minHue;
        return `hsl(${hue}, 68%, 38%)`;
    }
    
    // function getColour(percentage) {
    //     if(percentage === -1){
    //         return "#dc3545"
    //     }
    //    
    //     var perc = 100 - percentage;
    //    
    //     var r, g, b = 0;
    //     if(perc < 50) {
    //         r = 255;
    //         g = Math.round(5.1 * perc);
    //     }
    //     else {
    //         g = 255;
    //         r = Math.round(510 - 5.10 * perc);
    //     }
    //     var h = r * 0x10000 + g * 0x100 + b * 0x1;
    //     return '#' + ('000000' + h.toString(16)).slice(-6);
    // }


    const testSort = () => {
        // if(hasSortedResults)
        // {
        //     setHasSortedResults(false);
        //
        //     let sorted = [...playerAnswers];
        //     sorted.sort((a, b) => b.displayScore - a.displayScore);
        //     setPlayerAnswers(sorted);
        // }
        // else 
        // {
        //     sortResults();
        // }
        setShowConfetti(!showConfetti);
    }
    

    let flipKey = playerAnswers.map(e => e.answer.userId).join(',');
    return (
        <div className={styles.content_container}>
            <SizedConfetti
                width={width} height={height}
                style={{ pointerEvents: 'none' }}
                numberOfPieces={showConfetti ? 250 : 0}
                recycle={false}
                initialVelocityY={20}
                confettiSource={{
                    w: countdownWidth / 2,
                    h: 10,
                    x: countdownX + (countdownWidth / 4),
                    y: countdownY + (countdownHeight),
                }}
                onConfettiComplete={confetti => {
                    setShowConfetti(false)
                    confetti.reset()
                }}
            />

            <img className={styles.logo} src={logo} alt="Logo" />

            <div className={`${styles.container} ${styles.pointless}`}>
                 <div className={styles.left}/>
                <div className={styles.right}/>


                <div className={styles.pointless_container}>

                    <div className={styles.header} onClick={testSort}>
                        <Breadcrumbs {...props} />
                    </div>

                    <Flipper className={`${styles.score_container} ${showInfo ? styles.hidden : ""}`} flipKey={flipKey}
                             spring="stiff"
                             staggerConfig={{
                                 default: {
                                     speed: 0.1
                                 }
                             }}>

                        <div className={styles.pointless_content}>
                     
                            <div className={styles.player_answers}>
                                {buildPlayers()[0]}
                            </div>
                            
                            <div className={`${styles.countdown} ${showConfetti ? styles.zero : ""}`} ref={countdownRef}>
                                <span className={styles.countdown_text}>{counter}</span>
                                <div className={styles.countdown_inner} style={{height: `${counter}%`}}/>


                            </div>
                            
                            <div className={styles.player_answers}>
                                {buildPlayers()[1]}
                            </div>
    
                        </div>
                    </Flipper>

                    {showInfo ?
                        <Carousel>
                            <div className={styles.pointless_info}>

                                {answerResults.map((answer, index) =>
                                    <div key={index} className={styles.countdown_answer}>
                                        <div className={styles.score}><span>{answer.score}</span></div>
                                        <div className={styles.value}>{answer.value}</div>
                                    </div>
                                )}
                            </div>
                        </Carousel>

                       : null}
                </div>
            </div>
        </div>
    )
}