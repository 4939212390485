import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import HubContext from "utils/hubProvider";
import { Button, Media } from "components";
import { getSessionId } from "utils/helper";
import { useButtonTransition } from "hooks/useButtonTransition";
import quizApi from "services/QuizApi";
import moment from 'moment';
import styles from './Inputs.module.css';

export function TimeToSingInput(props) {
    const [answer, setAnswer] = useState(props.answer != null ? moment.utc(props.answer.answer[0], 'mm:ss.SS') : null);
    const hub = useContext(HubContext);
    const [error, setError] = useState(null);
    const { transition, transitionContent } = useButtonTransition()
    const [startTime, setStartTime] = useState(null);
    const [timeNow, setTimeNow] = useState(moment());
    const [hitMax, setHitMax] = useState(false);
    const [max, setMax] = useState(null);
    const [isRunning, setIsRunning] = useState(false);
    const [seconds, setSeconds] = useState(null);
    const [submitted, setSubmitted] = useState(props.answer != null);
    let interval = useRef(null);

    const submitCallback = useCallback(async (result) => {
        setAnswer(result);
        setSubmitted(false);

        const data = {
            "QuestionId": props.question.id,
            "Answer": [result.format("mm:ss.SS")],
        };

        var submitError = null;
        transition('.btn-submit', "Submitting...",
            async () => {
                await hub.invoke("Answer", getSessionId(), data)
                    .catch(async (error) => {
                        var res = await quizApi.answer(data);
                        if (!res.success)
                            throw new Error(res.error);
                    })
                    .catch((error) => {
                        submitError = "Failed to submit answer...";
                    })
                    .then(() => {
                        setSubmitted(true);
                    });
            },
            () => {
                setError(submitError);
            });
    }, [hub, props.question.id, transition]);


    const saveTime = () => {
        var tString = moment(seconds, 'ss').format("mm:ss") + moment.utc(timeNow).subtract(startTime).format(".SS")
        var time = moment(tString, 'mm:ss.SS');
        submitCallback(time);
    }

    //Setup Hub
    useEffect(() => {
        const running = (seconds, max, timerStart) => {
            console.log("RUNNING");
            setHitMax(false);
            setMax(max);
            setIsRunning(true);
            var momentDate = moment(timerStart);
            setStartTime(momentDate);
            setSeconds(seconds);
        }

        hub.off('timer-start');
        hub.on('timer-start', (running));

        hub.off('timer-tick');
        hub.on('timer-tick', running);

        hub.off('timer-stop');
        hub.on('timer-stop', (reachedMax) => {
            console.log(`STOPPED - ${reachedMax}`);
            setIsRunning(false);

            if (!reachedMax) {
                setStartTime(null);
            }
            else if (answer == null) {
                var now = moment()
                var diff = (moment.utc(now).add(max, 'seconds')).subtract(now);
                console.log(diff)
                submitCallback(diff);
                setHitMax(true);
            }

        });
    }, [hub, answer, submitCallback, max])


    useEffect(() => {
        console.log(`COUNT - ${seconds}`);

        clearInterval(interval.current)
        interval.current = null;

        if (startTime != null && seconds != null && answer == null && !hitMax) {
            interval.current = setInterval(() => {
                console.log("Interval")
                setTimeNow(moment())
            }, 10);
        }

        return () => {
            clearInterval(interval.current);
            interval.current = null;
        }
    }, [startTime, seconds, answer, hitMax])

    var buttonContent = null;


    if (answer && submitted) {
        buttonContent = hitMax ? <span className={styles.submitted_text}>Times Up!</span> : <span className={styles.submitted_text}>Submitted!</span>
    }
    else {
        buttonContent = <>
            <Button disabled={startTime == null} className="btn-submit mt-5" onClick={saveTime}>Stop Timer</Button>
            {error ? <span className="error">{error}</span> : null}
        </>
    }

    var time = "00:00.00";
    if (answer) {
        time = answer.format("mm:ss.SS");
    }
    else if (startTime != null) {
        if (hitMax) {
            time = "00:60.00"
        } else if (timeNow > startTime) {
            time = moment(seconds, 'ss').format("mm:ss") + moment.utc(timeNow).subtract(startTime).format(".SS");
        }
    }

    return (
        <div className={styles.container}>
            <Media media={props.media} isRunning={isRunning} />

            <h1 className={`mono ${styles.time_text}`}>
                <span>{time}</span>
            </h1>

            {buttonContent}
            {transitionContent}
        </div>
    )
}
