
import styles from '../Styles/QuestionView.module.css';
import { useAudioPlayer } from 'react-use-audio-player';
import React, { useState, useEffect, useRef, useContext } from "react";
import { delay } from 'utils/helper';
import ding from 'assets/media/correct.mp3';
import bloop from 'assets/media/bloop.mp3';
import VolumeContext from 'utils/volumeProvider';
import {Textfit} from "@namhong2001/react-textfit";

export function MultipleChoice(props) {
    const player = useAudioPlayer();
    const answerPosRef = useRef(null);
    const optionPosRef = useRef(null);
    const answerRef = useRef(null);
    const volume = useContext(VolumeContext);

    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [selectedAnswerConfirmed, setSelectedAnswerConfirmed] = useState(false)


    //On Answer
    useEffect(() => {
        player.load(bloop, { initialVolume: volume.sfx });

        setSelectedAnswerConfirmed(false);
        setSelectedAnswer(null);

        if (answerRef?.current != null)
            answerRef.current.style.transform = "";

        if (!props.answerView || props.showInfo)
            return;

        async function animate() {
            await delay(1000);

            let newArr = [...props.data.question.options];

            for (let index = 0; index < props.data.question.options.length; index++) {
                player.play();

                if (newArr.length === 0)
                    newArr = [...props.data.question.options];

                const randValue = newArr[Math.floor(Math.random() * newArr.length)];
                setSelectedAnswer(randValue);

                const removeIndex = newArr.indexOf(randValue);
                if (removeIndex > -1)
                    newArr.splice(removeIndex, 1)

                await delay(500);
            }

            player.load(ding, { autoplay: true, initialVolume: volume.sfx });

            setSelectedAnswer(props.data.answer.answer[0]);
            setSelectedAnswerConfirmed(true);
            props.onAnswerShown();

            var answerPosRect = answerPosRef.current.getBoundingClientRect();
            var optionRect = optionPosRef.current.getBoundingClientRect();
            var answerRect = answerRef.current.getBoundingClientRect();

            var index = props.data.question.options.indexOf(props.data.answer.answer[0]);
            console.log(index);



            var x = answerPosRect.x - optionRect.x - (optionRect.width / 2); 
            if (index % 2 === 0)
                x += (answerRect.width / 2);
            else
                x -= (answerRect.width / 2);

            var y = props.data.media ? 0 : answerPosRect.y - optionRect.y - (answerRect.height / 2);
            if (index > 1)
                y -= (optionRect.height / 2);
            


            answerRef.current.style.transformOrigin = props.data.media ? "top left" : "center center";
            answerRef.current.style.transform = `translate(${x}px, ${y}px) scale(1.5)`
        }

        animate();

    }, [props.answerView])

    
    var infoVisible = props.showInfo && props.data.question?.extraDetails.Info;
    return (
        <>
            <Textfit mode="multi" className={styles.question_text} max={48}>
                {props.data.question.question}
            </Textfit>

            {infoVisible?
                <div className={styles.info_text}>
                    <Textfit mode="single" className={styles.question_text} max={36}>
                        {props.data.question?.extraDetails.Info}
                    </Textfit>
                </div> : null}
               
                
             
            <div className={`${styles.options} ${selectedAnswerConfirmed ? styles.answer_confirmed : ''} ${infoVisible ? styles.hidden : ""}`} ref={optionPosRef}>

                <div className={styles.answer_position} ref={answerPosRef}></div>
                
                {props.data.question.options.map((option, index) => {
                    var selected = selectedAnswer === option;
                    var confirmed = selected && selectedAnswerConfirmed;
                    var isAnswer = props.data.answer.answer[0] === option;

                    return <p key={index} className={confirmed ? styles.selected : ""} ref={isAnswer ? answerRef : null}>
                        <span className={`${styles.option_num} ${selected ? styles.selected : ""}`}>{index + 1}</span>
                        {option}
                    </p>
                })}
            </div>
        </>
    );
}