import axios from 'axios';
import { apiErrorResult, apiSuccessResult } from './ApiResult';

const adminApi = {
    startDemoQuiz: async (demoCount) => {
        const URL = `api/admin/demo/${demoCount}`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    startQuiz: async () => {
        const URL = `api/admin/start`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    resetQuiz: async () => {
        const URL = `api/admin/reset`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    roundView: async (roundId) => {
        const URL = `api/admin/round-view/${roundId}`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    questionView: async (questionId) => {
        const URL = `api/admin/question-view/${questionId}`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    answerView: async (questionId) => {
        const URL = `api/admin/answer-view/${questionId}`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    scoresView: async () => {
        const URL = `api/admin/scores-view`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    end: async () => {
        const URL = `api/admin/end`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },
   
    adminData: async () => {
        const URL = `api/admin/data`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },


    saveBonuses: async (bonuses) => {
        const URL = `api/admin/bonuses`;
        return await axios.post(URL, bonuses)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    zoom: async (zoomIn) => {
        var URL = `api/admin/zoom/${zoomIn}`;
        if (zoomIn == null)
            URL = 'api/admin/zoom/fit'
        
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },


    audio: async (play) => {
        var URL = `api/admin/play-audio`;
        if (!play)
            URL = 'api/admin/pause-audio'
        
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    timer: async (start) => {
        var URL = `api/admin/timer/start`;
        if (!start)
            URL = 'api/admin/timer/stop'
        
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    countdown: async (start) => {
        var URL = `api/admin/countdown/start`;
        if (!start)
            URL = 'api/admin/countdown/stop'
        
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    changeStep: async (step) => {
        var URL = `api/admin/step/${step}`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    info: async (show) => {
        var URL = `api/admin/info/${show}`;
        return await axios.get(URL)
            .then((res) => apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },
}
export default adminApi; 