import React, { useState, useContext, useEffect, useCallback, createRef, useRef } from "react";

import styles from '../Styles/AnswerView.module.css';
import { Breadcrumbs } from 'components';
import logo from 'assets/images/quiz-logo.png';
import { PlayerImage } from "components/PlayerImage/PlayerImage";
import { delay } from "utils/helper";
import { useAudioPlayer } from 'react-use-audio-player';
import lineslide from 'assets/media/lineslide.mp3';
import tada from 'assets/media/tada.mp3';
import VolumeContext from "utils/volumeProvider";

export function DateAnswerView(props) {
    const start = parseInt(props.data.question.extraDetails.Min);
    const end = parseInt(props.data.question.extraDetails.Max);
    const answer = parseInt(props.data.answer.answer[0]);
    const validNumOfAnswers = props.data.playerAnswers.length > 1;
    const perfectAnswer = props.data.playerAnswers.some(e => e.answer[0] === props.data.answer.answer[0]);
    const volume = useContext(VolumeContext);

    const [players, setPlayers] = useState([]);
    const [answerShown, setAnswerShown] = useState(false);
    const [resultPosition, setResultPosition] = useState(null);

    const player = useAudioPlayer();

    useEffect(() => {
        setAnswerShown(false);
        setResultPosition(null);

        player.load(lineslide, {initialVolume: volume.sfx});        
        var users = [];
        props.data.playerAnswers.forEach(userAnswer => {
            var date = parseInt(userAnswer.answer[0]);
            var user = props.data.players.find(e => e.id === userAnswer.userId);

            users.push({
                user: user,
                date: date,
                score: userAnswer.score,
            })
        });

        const result = Object.groupBy(users, ({ date }) => date);

        setPlayers(result);

        setTimeout(() => {
            showAnswer()
        }, 3000);
    }, [])

    async function showAnswer() {
        if (!validNumOfAnswers)
            return;

        const timeline = document.getElementById('timeline');
        if (timeline) {
            const pins = timeline.querySelectorAll(`.${styles.pin_align_container}`);
            const line = timeline.querySelector(`.${styles.line}`);
            var lineRect = line.getBoundingClientRect();

            var positions = []
            pins.forEach(pin => {
                var dateSelector = pin.querySelector(`.${styles.date} span`);
                var date = parseInt(dateSelector.innerHTML);

                var pinRect = dateSelector.getBoundingClientRect();

                var offset = (pinRect.left - 10) - lineRect.left;

                positions.push({
                    date: date,
                    position: offset,
                    diff: answer - date
                });
            });

            positions.sort((a, b) => Math.abs(a.diff) - Math.abs(b.diff));

            var answerPos = "50%";
            if (positions.length > 1) {
                var dates = positions.map(e => e.date);
                var maxDate = Math.max.apply(Math, dates);
                var maxPos = positions.find(e => e.date === maxDate).position;

                var minDate = Math.min.apply(Math, dates);
                var minPos = positions.find(e => e.date === minDate).position;

                var dateDiff = maxDate - minDate;
                var posDiff = maxPos - minPos;
                var posPerDate = posDiff / dateDiff;

                answerPos = positions[0].position + (posPerDate * positions[0].diff)
            }
            else {
                if (answer < positions[0].date) {
                    //between start and middle
                    var dateDiffStart = positions[0].date - start;
                    var posPerDateStart = 50 / dateDiffStart;
                    answerPos = `${(answer - start) * posPerDateStart}%`;
                }
                else if (answer > positions[0].date) {
                    //between end and middle
                    var dateDiffEnd = end - positions[0].date;
                    var posPerDateEnd = 50 / dateDiffEnd;
                    answerPos = `${(50 + (end - answer) * posPerDateEnd)}%`;
                }
                else {
                    answerPos = positions[0].position;
                }
            }

            var lineMin = 0;
            var lineMax = lineRect.width;
            var lineMiddle = (lineMax - lineMin) / 2;


            var randMax1 = Math.floor(Math.random() * ((lineMax * 0.9) - (lineMax * 0.6)) + (lineMax * 0.6));
            var randMax2 = Math.floor(Math.random() * ((lineMax * 0.9) - (lineMax * 0.6)) + (lineMax * 0.6));
            var randMin1 = Math.floor(Math.random() * ((lineMax * 0.5) - (lineMax * 0.1)) + (lineMax * 0.1));

            setResultPosition(lineMiddle);
            await delay(500);

            var moveDelay = 2000;
            setResultPosition(randMax1);
            player.play();
           
            await delay(moveDelay);
            setResultPosition(randMin1);
            player.play();

            await delay(moveDelay);
            setResultPosition(randMax2);
            player.play();

            await delay(moveDelay);
            setResultPosition(answerPos);
            player.play();

            await delay(moveDelay);
            setAnswerShown(true);
            player.load(tada, {autoplay: true, initialVolume: volume.sfx});

        }
    }

    function pinMarker() {
        return (
            <div className={`${styles.pin} ${styles.marker}`}>
                <span className={styles.marker_line} />
            </div>
        );
    }

    function pinDate(date) {
        var isAnswer = answer.toString() === date;
        return (
            <div className={`${styles.pin} ${styles.date} ${isAnswer ? styles.is_answer : ""}`}>
                <span>{date}</span>
            </div>
        );
    }

    function buildPins() {
        var keys = Object.keys(players);

        var pins = [];
        // var pinsBottom = [];
        pins.push(<div key="pin-start" style={{ flex: keys[0] - start }}></div>);
        // pinsBottom.push(<div key="pin-bottom-start" style={{ flex: keys[0] - start }}></div>);

        for (let i = 0; i < keys.length; i++) {
            var key = keys[i];

            var keyInt = parseInt(key);
            var nextKey = i + 1 < keys.length ? keys[i + 1] : end;
            var flex = parseInt(nextKey) - keyInt;

            var users = players[key];

            var pin = <div key={i} className={styles.pin_align_container} style={{ flex: flex }}>
                <div className={styles.pin_group_container}>
                    {buildPinGroup(users)}
                    {pinDate(key)}
                </div>
            </div>

            pins.push(pin);
        }

        return pins;
    }

    function buildPinGroup(users) {
        var pins = [];
        var pinsBottom = [];

        for (let i = 0; i < users.length; i++) {
            const user = users[i];

            if (i % 2 === 0) {
                pins.push(
                    <div key={i} className={`${styles.pin} ${styles.name}`}>
                        {answerShown && user.score ? <span className={styles.score}>+{user.score}</span> : null}
                        <PlayerImage className={styles.user_image} player={user.user} />
                        <div className={styles.connector} />
                    </div>
                )
            }
            else {
                pinsBottom.push(
                    <div key={i} className={`${styles.pin} ${styles.name}`}>
                        {answerShown && user.score ? <span className={styles.score}>+{user.score}</span> : null}
                        <PlayerImage className={styles.user_image} player={user.user} />
                        <div className={styles.connector} />
                    </div>
                )
            }
        }

        return <>
            <div className={styles.pin_group}>
                {pins}
                {pinMarker()}
                {pins.length > 1 ? <span className={styles.multi_connector} /> : null}
            </div>
            {pinsBottom.length > 0 ? <div className={`${styles.pin_group} ${styles.bottom}`}>
                {pinsBottom}
                {pinMarker()}
                {pinsBottom.length > 1 ? <span className={styles.multi_connector} /> : null}
            </div> : null}
        </>
    }

    var hideResults = validNumOfAnswers && resultPosition === null;
    var answered = !validNumOfAnswers || answerShown;
    var pos = validNumOfAnswers ? resultPosition : "50%";

    return (
        <div className={styles.content_container}>
            <img className={styles.logo} src={logo} alt="Logo" />

            <div className={styles.container}>
                <div className={styles.left} />
                <div className={styles.right} />


                <div className={styles.date_container}>

                    <div className={styles.header}>
                        <Breadcrumbs {...props} />
                    </div>

                    <div className={styles.date_content}>
                        <span className={`${styles.result_answer} ${answered ? styles.answered : ''}`}>
                            {answer}
                        </span>

                        <div id="timeline" className={`${styles.timeline} ${answered ? styles.answered : ''}`}>

                            <div className={styles.line}>
                                {hideResults ? null : <div className={`${styles.result_circle} ${!perfectAnswer ? styles.no_perfect : ""}`} style={{ left: pos }} />}
                                <div className={styles.pins}>
                                    {buildPins()}
                                </div>
                            </div>
                            <div className={styles.start} />
                            <div className={styles.end} />

                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}