import {Textfit} from "@namhong2001/react-textfit";
import styles from '../Styles/QuestionView.module.css';
import React from "react";

export function Date(props) {

    return (
        <>
            <Textfit mode="multi" className={styles.question_text} max={48}>
                {props.data.question.question}
            </Textfit>

            <div className={styles.text_question_type}>
                <p>Enter an date on your device betwen {props.data.question.extraDetails.Min} and {props.data.question.extraDetails.Max}</p>
            </ div>
        </>
    )
}