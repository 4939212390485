import {useCallback, useEffect, useState} from "react";
// useDimensions.js

import { useMemo, useSyncExternalStore } from "react"

function subscribe(callback) {
    window.addEventListener("resize", callback)
    return () => {
        window.removeEventListener("resize", callback)
    }
}

function useDimensions(ref) {
    const dimensions = useSyncExternalStore(
        subscribe,
        () => {
            var box = ref.current?.getBoundingClientRect();
            return JSON.stringify({
                x: ref.current?.offsetLeft ?? 0,
                y: ref.current?.offsetTop ?? 0,
                width: ref.current?.offsetWidth ?? 0, // 0 is default width
                height: ref.current?.offsetHeight ?? 0, // 0 is default height
            })


            // var box = ref.current?.getBoundingClientRect();
            // console.log(box);
            // return JSON.stringify({
            //     x: box?.x ?? 0,
            //     y: box?.y ?? 0,
            //     width: box?.width ?? 0, // 0 is default width
            //     height: box?.height ?? 0, // 0 is default height
            // });
        }
    )
    return useMemo(() => JSON.parse(dimensions), [dimensions])
}

export { useDimensions }