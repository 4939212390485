import styles from './Styles/QuestionView.module.css';
import { Breadcrumbs, Media, PlayerAnswers } from 'components';
import { QuestionTypeOptions } from './QuestionTypes/QuestionTypeOptions';
import logo from 'assets/images/quiz-logo.png';
import { Countdown } from 'components/Countdown/Countdown';
import { QuestionType } from 'enumerations/QuestionType';
import {useContext, useEffect, useState} from "react";
import HubContext from "../../utils/hubProvider";

export function ViewerWithMedia(props) {
    const hub = useContext(HubContext);
    const [showInfo, setShowInfo] = useState(false)

    //Setup Hub
    useEffect(() => {
        hub.off('info-toggle');
        hub.on('info-toggle', (show) => {
            setShowInfo(show);
        });
    }, [hub])
    
    var showPlayersAnswers = props.data.question.type !== QuestionType.TimeToSing;
    return (
        <div className={styles.content_container}>
            <img className={styles.logo} src={logo} alt="Logo" />

            <div className={styles.container}>

                <div className={styles.left}>
                    <div className={styles.header}>
                        <Breadcrumbs {...props}/>
                    </div>

                    <div className={styles.left_content}>                    
                        <QuestionTypeOptions {...props} showInfo={showInfo} />
                    </div>
                </div>

                <div className={`${styles.right}`}>
                    <div className={styles.header}></div>

                    <div className={styles.right_content}>
                        <div className={styles.media_container}>
                            <Media media={props.data.media} isRunning={props.playing} />
                        </div>
                    </div>
                </div>
            </div>

            {/* {props.data.question.type === QuestionType.ConfirmChoice ? <Countdown className={styles.countdown} icon="timer" /> : null } */}
            {showPlayersAnswers ?
                <PlayerAnswers players={props.players} answers={props.answers} showCorrectPlayers={props.showCorrectPlayers} /> 
                : null}
        </div>
    )
}