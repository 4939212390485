import React, {useRef} from "react";
import styles from './Carousel.module.css';
import {useDimensions} from "../../hooks/useContainerDimensions";


export function Carousel(props) {

    const containerRef = useRef(null)
    const { width: containerWidth } = useDimensions(containerRef)
    
    const infoRef = useRef(null)
    const { width: infoWidth } = useDimensions(infoRef)
    
    return (
        <>

            <div className={styles.carousel_container} ref={containerRef}>

                {infoWidth < containerWidth ?

                    <div className={`${styles.carousel_item} ${styles.single}`}>
                        {props.children} 
                    </div>: 
                
                    <>
                        <div className={styles.carousel_placeholder} ref={infoRef}>{props.children}</div>
                        
                        <div className={styles.carousel_track} style={{width: infoWidth * 2}}>
                            <div className={styles.carousel_item} style={{width: infoWidth}}>
                                {props.children}
                            </div>
                            <div className={styles.carousel_item} style={{width: infoWidth}}>
                                {props.children}
                            </div>
                        </div>
                    </>
                }
            </div>
        </>

    );
}