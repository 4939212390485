import React, { useState, useContext, useEffect } from "react";
import HubContext from "utils/hubProvider";

import adminApi from "services/AdminApi";
import { AdminProgress } from "../Admin/AdminProgress/AdminProgress";
import { QuizState } from "enumerations/QuizState";
import logo from 'assets/images/quiz-logo.png';
import { PlayerAnswer } from "./PlayerAnswer";


export function ValidatorContent(props) {
    const hub = useContext(HubContext);
    const [data, setData] = useState(props.data);

    //Setup Hub    
    useEffect(() => {
        if (hub == null)
            return;

        hub.off('state-changed');
        hub.on('state-changed', refreshData);

        hub.off('answered');
        hub.on('answered', refreshData);

        hub.off('validated');
        hub.on('validated', refreshData);
    }, [hub])

    async function refreshData() {
        var adminData = await adminApi.adminData();
        console.log(adminData);
        setData(adminData.data);
    }

    if (data.state !== QuizState.Question && data.state !== QuizState.Answer) {
        return (
            <div className="validator-content p-3">
                <div className='validator-start'>
                    <img src={logo} alt="Logo" />
                    {
                        data.state === QuizState.AwaitingStart ? <p>Waiting for quiz to start</p> :
                            data.state === QuizState.End ? <p>The End</p> :
                                <p>Waiting for question</p>
                    }
                </div>
            </div>
        )
    }

    if (data == null)
        return null;


    var userAnswers = data.playerAnswers.filter(e => e.requiresValidation);
    var validated = data.playerAnswers.filter(e => e.validated);
    var roundQuestionCount = data.questions.filter(e => e.round === data.question.round).length;

    return (
        <div className="validator-content p-3">
            <AdminProgress progress={userAnswers.length} progress2={validated.length} total={data.players.length} label="Answered" label2="Validated" icon="account_circle" />

            <div className="validator-header">
                <span className="round-num">Round {data.question.round} <span className="total">/ {data.rounds.length}</span></span>
                <span className="question-num">Question {data.question.number} <span className="total">/ {roundQuestionCount}</span></span>

                <div className="seperator" />
            </div>

            <div className="validator-scroll-content">
                <div className="validator-scroll-content-header">
                    <span className="validator-question-text">{data.question.question}</span>

                    <div className="validator-answer-text">
                        {data.answer.answer.map((answer, index) => (
                            <span key={index}>{answer}</span>
                        ))}
                    </div>
                </div>
                <div className='validator-answers'>
                    {userAnswers.length > 0 ? userAnswers.map((userAnswer, index) => {
                        var player = data.players.find(e => e.id === userAnswer.userId);

                        return <PlayerAnswer key={index} player={player} playerAnswer={userAnswer}
                            hub={hub} />
                    }) : <div className="p-3 nothing-to-validate">Nothing to validate</div>}
                </div>
            </div>


        </div>
    )

}