import {Textfit} from "@namhong2001/react-textfit";
import styles from '../Styles/QuestionView.module.css';
import React from "react";

export function Map(props) {

    return (
        <>
            <Textfit mode="multi" className={styles.question_text} max={48}>
                {props.data.question.question}
            </Textfit>
            
            <div className={styles.text_question_type}>
                <p>Tap on the map where you think the location is</p>
                <br />
                <p className={styles.small}>Hint: You can use the full screen and zooms button to help</p>
            </ div>
        </>
    )
}