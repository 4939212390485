import styles from '../Styles/QuestionView.module.css';
import React, { useState, useEffect, useContext, Fragment } from "react";
import HubContext from "utils/hubProvider";
import { useAudioPlayer } from 'react-use-audio-player';
import { delay } from 'utils/helper';
import ding from 'assets/media/correct.mp3';
import bloop from 'assets/media/bloop.mp3';
import VolumeContext from 'utils/volumeProvider';
import {Textfit} from "@namhong2001/react-textfit";

export function ConfirmChoice(props) {
    const [step, setStep] = useState(props.data.step);
    const hub = useContext(HubContext);
    const player = useAudioPlayer();
    const [showAnswer, setShowAnswer] = useState(false)
    const [hideOption, setHideOption] = useState(false)
    const volume = useContext(VolumeContext);

    //Setup Hub
    useEffect(() => {
        hub.off('step-changed');
        hub.on('step-changed', (step) => setStep(step));
    }, [hub])

    //On Answer
    useEffect(() => {
        player.load(bloop, { initialVolume: volume.sfx });

        setShowAnswer(false);
        setHideOption(false);

        if (!props.answerView)
            return;

        async function animate() {
            await delay(1000);
            player.play();
            await delay(500);
            player.play();
            await delay(500);
            player.play();
            setHideOption(true);
            await delay(500);
            player.play();
            await delay(500);

            player.load(ding, { autoplay: true, initialVolume: volume.sfx });

            setShowAnswer(true);
            props.onAnswerShown();

        }

        animate();

    }, [props.answerView]);

    return (
        <>
            <Textfit mode="multi" className={styles.question_text_confirm} max={28}>
                {props.data.question.question}
            </Textfit>

            <div className={styles.confirm_choice_question_type}>
                {showAnswer ?
                    <div className={`${styles.answers} py-5`}>
                        {props.data.answer.answer.map((answer, index) =>
                            <p key={index}>{answer}</p>
                        )}
                    </div>
                    : <div className={`${styles.choice_group} ${hideOption ? styles.hide_option : ""}`}>
                        {props.data.question.options.slice(0, step + 1).map((option, index) => {
                            var altClass = index % 2 !== 0 ? styles.alt : null

                            return <Fragment key={index}>
                                <p className={altClass}>{option}</p>
                                <br />
                            </Fragment >
                        })}
                    </div>
                }

            </ div>
        </>
    )
   
}