import { Button } from "components";
import { QuestionType } from "enumerations/QuestionType";

export function AdminButtons(props) {
    var buttons = [];

    switch (props.data.question.type) {
        case QuestionType.TimeToSing:
            buttons.push(
                <Button small icon onClick={() => props.timer(true)}>timer_play</Button>,
                <Button small icon onClick={() => props.timer(false)}>timer_pause</Button>
            );          
            break;
        case QuestionType.Map:
            buttons.push(
                <Button small icon onClick={() => props.zoom(true)}>zoom_in</Button>,
                <Button small icon onClick={() => props.zoom(false)}>zoom_out</Button>,
                <Button small icon onClick={() => props.zoom(null)}>location_on</Button>
            );
            break;
        // case QuestionType.ConfirmChoice:
        //     buttons = <>
        //         <Button small icon onClick={() => props.countdown(true)}>play_arrow</Button>
        //         <Button small icon onClick={() => countdown(false)}>stop</Button>
        //     </>
        //     break;
        default:
            buttons.push(
                <Button small icon onClick={() => props.audio(true)}>play_arrow</Button>,
                <Button small icon onClick={() => props.audio(false)}>stop</Button>
            );
    }

    if(props.data.question.extraDetails?.Info || props.data.question.type === QuestionType.Pointless){
        buttons.push(
            <Button className={props.infoShown ? "info_shown" : "info_not_shown"} small icon onClick={() => props.info()}>info_i</Button>,
        )
    }

    return (<div className="admin-buttons">
        <Button small icon onClick={() => props.showBonuses()}>manage_accounts</Button>
        <Button small icon onClick={() => props.viewScores()}>leaderboard</Button>


            {buttons.map(button => <> {button} </>)}
        </div>
    );
}